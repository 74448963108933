import React, { useEffect, useState, useRef, useImperativeHandle,forwardRef } from 'react';
import AudioPlayer from '../yoyuma-common/audioplayer/AudioPlayer';
import tracks from './tracks';
import Backendless from 'backendless';
import BreathCountDown from '../yoyuma-common/countdown/BreathCountDown';

const Meditation = forwardRef((props,ref) => {
  let startDelay = 10;
  const [meetingInfo, setMeetingInfo] = useState(props.meetingInfoProp);
  const [meetingStartReady, setMeetingStartReady] = useState(false);
  const [adPageConfig, setAdPageConfig] = useState(null);
  const [remainingSecs, setRemainingSecs] = useState(startDelay);
  const [breathCycle, setBreathCycle] = useState(0);
  //const [isShowPlayer, setIsShowPlayer] = useState(true);
  const [isPlayAudio, setIsPlayAudio] = useState(true);
  const [roomInfo, setRoomInfo] = useState(null);
  const audioPlayer = useRef(null);

  
  useEffect(() => {
    if(roomInfo==null){
      loadAdPageConfiguration();
    }
  });
  

  useEffect(() => {
    if(props.meetingInfoProp){
      setMeetingInfo(props.meetingInfoProp);

    }
  },[props.meetingInfoProp]);

  
  const addMessageStatusListner = (meeting) => {
      //subscribe to meeting status channel listner
      let channel = Backendless.Messaging.subscribe("meeting_status");
      //add selector to list to the required meeting only
      let selector = `meeting = '${meeting.objectId}'`; 
      channel.addMessageListener(selector,onMessage);
  }
  
  const onMessage = ( message ) => {
    const status = message.message;
    //console.log("-------------------" + status)
    switch(status) {
      case "STARTED":
        startMeeting();
        break;
      case "STARTING":
        let updateMeetingInfo = Object.assign({}, meetingInfo);
        updateMeetingInfo.status = status;
        setMeetingInfo(updateMeetingInfo);
        break;
      default:
        //Nothing to do
    }

  }
  
  
  
  const onMeetingStarted = (link) => {
    //if(isShowPlayer){
      applyMeetingStart();
    //}
  };
  
  const applyMeetingStart = () => {
    const data = { 
      objectId:meetingInfo.objectId,
      status:"STARTED" 
    };
    setTimeout(function(){
      Backendless.Data.of('meeting').save(data)
      .then(obj => {
          startMeeting();
      })
      .catch(error => {
        console.log(error);
        alert("Please try again! \n " + error);
      })
    },100)
  }


  
  const startMeeting = () => {
      setIsPlayAudio(false);
      //Show advertisement page if enabled
      if(adPageConfig && adPageConfig.pageenable){
        setMeetingStartReady(true);
        //Add body event listner 
        document.body.addEventListener('click', joinMeeting);
        document.body.classList.add("click-body");
      }else{
        window.location.href = meetingInfo.link;
      }
  }

  const joinMeeting = () => {
      //
      document.body.removeEventListener('click', joinMeeting);
      document.body.classList.remove("click-body");
      document.body.classList.remove("player-body");
      //
      //Open meeting
      window.open(meetingInfo.link);
     
      //show ad page content
      document.title = adPageConfig.title;
      document.body.innerHTML = adPageConfig.content;
      //TODO: Eval script is risky
      // eslint-disable-next-line
      eval(adPageConfig.script);
  }
  

    //Load Configuration
  
  const loadAdPageConfiguration = () => {
    const whereClause = `key = 'room_ad_page_info' || key = 'room_default_info' `;
    let queryBuilder = Backendless.DataQueryBuilder.create().setWhereClause( whereClause );
    queryBuilder.addProperties('data');
    Backendless.Data.of( "system_configuration" ).find( queryBuilder )
    .then(function(info){
      //console.log(info);
      if(info && info.length>0){
        let defaultInfo = JSON.parse(info[0].data);
        setAdPageConfig(defaultInfo);
        if(info.length>1){
          let roomInfo = JSON.parse(info[1].data);
          setRoomInfo(roomInfo);
          //apply delay if configured
          if(roomInfo.hostStartDelay !=null){
            //use host start delay timer 
            startDelay = parseInt(roomInfo.hostStartDelay);
            setBreathCycle(parseInt(roomInfo.breathCycle));
            setRemainingSecs(startDelay);
          }
          // add meeting status listner
          addMessageStatusListner(meetingInfo);
       }
      
       
      }
    })
    .catch( function( error ) {
        console.log(error);
    });
  }
  

  useImperativeHandle(ref, () => ({
    onStartRoom  () {
      audioPlayer.current.onClickContinue();
    }
  }))

  const onCountEnd = () => {
    if(meetingInfo)
      onMeetingStarted(meetingInfo.link);
  };

  return meetingInfo ? (
      <div> 
          <AudioPlayer
              ref={audioPlayer}
              mainTitle={meetingInfo?(meetingStartReady?adPageConfig.startdescription:meetingInfo.title):""}
              description={ meetingInfo ? meetingInfo.description : "" }
              countdownPrompt={ meetingInfo && meetingInfo.status==="STARTING" && !meetingStartReady && roomInfo  && breathCycle > 0
                ?<BreathCountDown opacity={roomInfo.breathsTransparancy?roomInfo.breathsTransparancy:100} key ="countdown" breathCycle = {breathCycle} remainingSecs = {remainingSecs} onCountEnd = {onCountEnd} />
                :<span></span>}
              tracks={tracks} 
              enableTrackInfo = {false}
              enableProgress = {meetingInfo.enableMusic}
              enableControls = {meetingInfo.enableMusic}
              enableCountdown = { meetingInfo.status==="STARTING"}
              autoStart = {meetingInfo.enableMusic && isPlayAudio}
              isShowPlayer = {true}
              meetingInfo={meetingInfo} 
              meetingStatus = {meetingInfo?meetingInfo.status:""}
          />
      </div>
    ) : (<div></div>);
  });

export default Meditation;