import './App.css';
import {
  HashRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Backendless from 'backendless';
import StartRoom from './pages/StartRoom';
import ManageRoom from './pages/ManageRoom';

const { REACT_APP_BACKENDLESS_SERVER_URL,REACT_APP_BACKENDLESS_APP_ID,REACT_APP_BACKENDLESS_API_KEY } = process.env;
Backendless.serverURL = REACT_APP_BACKENDLESS_SERVER_URL;
Backendless.initApp(REACT_APP_BACKENDLESS_APP_ID, REACT_APP_BACKENDLESS_API_KEY);

function App() {

  return (
    <Router>
        <Switch>
          <Route exact path="/" render={(props) => <ManageRoom {...props} editMode={false} showForm={false} />} />
          <Route exact path="/manage" render={(props) => <ManageRoom {...props} editMode={false} showForm={false} showRooms={true}/>} />
          <Route exact path="/edit" render={(props) => <ManageRoom {...props} editMode={true}  showForm={true}/>} />
          <Route exact path="/new" render={(props) => <ManageRoom {...props} editMode={false}  showForm={true}/>} />
          <Route exact path="/start" component={StartRoom} />
        </Switch>
      </Router>
 );
}

export default App;