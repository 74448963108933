import React from "react";
import Meditation from '../ui/Meditation';
import { useEffect,useState,useRef,useImperativeHandle,forwardRef } from "react";
import Backendless from 'backendless';
import ShareURL from '../ui/ShareURL';

const StartRoomPanel = forwardRef((props,ref) => {
  const [isLoading, setIsLoading] = useState(false);
  //const [isStarting, setIsStarting] = useState(false);
  const [meetingInfo, setMeetingInfo] = useState(props.meetingInfoProp);
  const [expiry, setExpiry] = useState(20);
  const [isShowStart, setIsShowStart] = useState(false);
  const meditation = useRef(null);

  useEffect(() => {
    if(props.meetingInfoProp){
      setMeetingInfo(props.meetingInfoProp);
    }
  },[props.meetingInfoProp]);

  useEffect(() => {
    //console.log(meetingInfo);
    if(meetingInfo){
       switch(meetingInfo.status){
        case "STARTED":
          //If meeting started redirct to the meeting URL
          window.location.href = meetingInfo.link;
          break;
        case "STARTING":
          //If meeting is starting show count down window
          //setIsStarting(true);
          break;
        default:
          //subscribe to meeting status channel listner
          let channel = Backendless.Messaging.subscribe("meeting_status");
          //add selector to list to the required meeting only
          let selector = `meeting = '${meetingInfo.objectId}'`; 
          channel.addMessageListener(selector,onMessage);
      }
    }
  },[meetingInfo]);

  const onMessage = ( message ) => {
    //Nothing to do
    //console.log("asdasdasd",message);
  }

  const startMeeting = () => {
      if(expiry){
        setIsLoading(true);
        const data = { 
          objectId:meetingInfo.objectId,
          status:"STARTING",
          expiry:expiry
        };
        setTimeout(function(){
          Backendless.Data.of('meeting').save(data)
          .then(obj => {
            setIsLoading(false);
            setMeetingInfo(obj)
          })
          .catch(error => {
            setIsLoading(false);
            console.log(error);
            alert("Please try again! \n " + error);
          })
        },100)
      }else{
        alert("Reset value is required!");
      }
     
     
  }

  const startMeetingNow = () => {
    if(window.confirm("Start meeting now ?"))
      startMeeting();
  }


  const onExpiryChange = (event) => {
    setExpiry(event.target.value);
  }


    const toggleStartMeeting = () => {
      setIsShowStart(!isShowStart);
    }

    useImperativeHandle(ref, () => ({
      onStartRoom () {
        //call on start room
        meditation.current.onStartRoom();
      }
    }))

  return meetingInfo ? (
    <div>
     <div id="room-holder" className="room-created-box" >
     <div className="room-created-info-box" >
      <div style={isShowStart && meetingInfo.status==="WAITING"?{display:"block"}:{display:"none"}} className="room-created-info">
         
          {(meetingInfo.status==="WAITING" &&
            <div>
              <div className="closeIcon" onClick={toggleStartMeeting}><span>x</span></div>
              <p>Click on start button to start your meeting</p>
              <p>
                Reset meeting after <input type="number" name="expiry" defaultValue={expiry} step="1" min="0" className="expiry-input" onChange={onExpiryChange}/> Minute(s) - <i>0 never reset</i>  
              </p>
              {isLoading?<span>Starting...</span>:<button className="main" id="start-button" onClick={startMeeting}>Start Meeting</button>}
            </div>
            
            )}
      </div>
      
      </div>
      <Meditation  ref={meditation} meetingInfoProp={meetingInfo} slug={meetingInfo.slug} />
      <div className="host-message">
        <div className="item" id="startnowbutton" title="Start meeting now!" onClick={startMeetingNow}><span><i class="fa fa-play" aria-hidden="true"></i></span> <br></br>Quick Start</div>
        <div className="item" id="configureresetbutton" title="Start with reset" onClick={toggleStartMeeting}><span><i class="fa fa-clock-o" aria-hidden="true"></i></span> <br></br>Config. Start</div>
        <div className="item"><a id="editroom" href={"/index.html#/edit?slug="+meetingInfo.slug} rel="noreferrer" target="_blank"><span><i class="fa fa-pencil" aria-hidden="true"></i></span> <br></br>Edit Room</a></div>
        <ShareURL slug={meetingInfo.slug} />
      </div>
    </div>
    
    </div>
  ) : (<div></div>);
});

export default StartRoomPanel;
