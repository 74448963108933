import React, { useRef } from "react";
import { useEffect,useState } from "react";
import Backendless from 'backendless';
//const { REACT_APP_HOST_USER } = process.env;


const LoginPanel = ({onLogin,autoLogin, roomMode = false, editMode = false, editProfile = false}) => {
  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const getSearchParams =  (searchParam) => {
    let params = window.location.hash.split('?')[1];
    if(params){
      params = params.split("&");
      for(let i=0;i<params.length;i++){
        let param = params[i];
        if(param.indexOf(searchParam)!==-1){
          return decodeURI(param.split("=")[1]);
        }
      }
    }else{
      return null;
    }
  } 
  const [isReady, setIsReady] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [meetingInfo, setMeetingInfo] = useState(null);
  const [slug, setSlug] = useState(getSearchParams('slug'));
  const [user, setUser] = useState(null);
  const [password, setPassword] = useState(null);
  const [username, setUsername] = useState(null);
  const [fullname, setFullname] = useState(null);
  const [cacheLogin, seCachelogin] = useState(false);
  const [email, setEmail] = useState(null);
  const [sPassword, setSPassword] = useState(null);
  const [cPassword, setCPassword] = useState(null);
  const [fEmail, setFEmail] = useState(null);
  const enableCacheLogin = false;
  
  const [error, setError] = useState(null);
  const [loggedInMode, setLoggedInMode] = useState(false);
  
  const [loginmode, setLoginmode] = useState(true);
  const [signupmode, setSignupmode] = useState(false);
  const [forgetpasswordmode, setForgetpasswordmode] = useState(false);

  
  const prevSlug = usePrevious(slug);
  const slugTextInput = useRef(null);
  
  useEffect(() => {
    if(prevSlug!==slug){
      getMeetingInfo(slug);
    }
  });


  const getMeetingInfo = (slug) => {
    //only request meeting info for room mode
    if(roomMode) {
      setIsLoading(true);
      //let whereClause = `slug = '${slug}'`;
      //let queryBuilder = Backendless.DataQueryBuilder.create().setWhereClause( whereClause );
      Backendless.APIServices.invoke('CodelessParticipantKeepAliveService', 'checkmeeting', slug)
      //Backendless.Data.of( "meeting" ).find( queryBuilder )
      //.then( function( meetings ) {
        //let meeting =  meetings[0];
        .then(meeting => {
          setIsLoading(false);
          if(meeting){
          //if(meetings && meetings[0]){
          setMeetingInfo(meeting);
          //Auto login if room button is clicked
          if(autoLogin || user!=null){
            onUserLogin(meeting, user);
          }
        
        }else{
          alert("Meeting not found!");
          setMeetingInfo(null);
        }
      })
      .catch( function( error ) {
        setIsLoading(false);
        console.log(error);
        setMeetingInfo(null);
      });
    }
  }

  const onChangeSlug = (event) => {
    setSlug(event.target.value);
  }

  const onChangePassword = (event) => {
    setPassword(event.target.value);
  }

  const onChangeUsername = (event) => {
    setUsername(event.target.value);
  }

  const onChangeFullname = (event) => {
    setFullname(event.target.value);
  }
  const onChangeEmail = (event) => {
    setEmail(event.target.value);
  }
  const onChangeSPassword = (event) => {
    setSPassword(event.target.value);
  }
  const onChangeCPassword = (event) => {
    setCPassword(event.target.value);
  }
  const onChangeFEmail = (event) => {
    setFEmail(event.target.value);
  }
  const onChangeCacheLogin = (event) => {
    seCachelogin(event.target.value);
  }

  

  const login = () => {
    if(password!=null && password.length>0 && username!=null && username.length>0){
      setIsLoading(true);
      //Call authenticate service
      Backendless.UserService.login(username,password,true)
      .then( function( user ) {
        setIsLoading(false);
        setUser(user);
        setEmail(user.email);
        setFullname(user.name);
        onUserLogin(meetingInfo,user);
      }).catch( function( error ) {
        setIsLoading(false);
        setError(error+"");
        //alert(error);
      });
    }else{
      alert("Username and Password are required!");
    }
  }

  const signupUser = () => {
    if(fullname!=null && fullname.length>0 && isValidEmail(email) && sPassword!=null && sPassword.length>=8 && sPassword === cPassword){
      setIsLoading(true);
      setError(null);
      if(editProfile){
        user.name = fullname;
        user.password = sPassword;
        Backendless.UserService.update( user ).then( userRegistered ).catch( gotError );
      }else{
        let newuser = new Backendless.User();
        newuser.name = fullname;
        newuser.password = sPassword;
        newuser.email = email;
        Backendless.UserService.register( newuser ).then( userRegistered ).catch( gotError );
      }
     
    }else{
      let error = "Missing information!";
      if(email!=null&& !isValidEmail(email)){
        error +=" Invalid Email.";
      }
      if(sPassword!=null && sPassword.length<8){
        error +=" Password must be at least 8 Characters.";
      }
      if(sPassword!=null &&  cPassword!=null && sPassword !== cPassword){
        error +=" Password and confirm password does not match.";
      }
      setError(error);
    }
  }

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  }

  const userRegistered = ( user ) => {
    setIsLoading(false);
    alert(editProfile ? "User Updated" : "Please check your email to confirm registration" );
    if(editProfile){
      window.location.reload();
    }else{
      if(cacheLogin){
        login();
      }else{
        loginMode();
      }
      
    }
  }

  const resestUserPassword = () => {
    if(fEmail!=null && fEmail.length>0){
      if(window.confirm("Your old password will be reset! Are you sure you want to continue?")){
        setIsLoading(true);
        setError(null);
        Backendless.UserService.restorePassword(fEmail).then( passwrodReset ).catch( gotError );
      }
    }else{
      let error = "Email is required!";
      setError(error);
    }
  }
  
  const passwrodReset = ( o ) => {
    setIsLoading(false);
    alert("Password has been reset!");
    loginMode();
  }
  
  const gotError = ( err ) => {// see more on error handling
    setIsLoading(false);
    console.log( "error message - " + err.message );
    console.log( "error code - " + err.statusCode );
    setError(err.message);
  }
  

  const handleKeypress = e => {
    //it triggers by pressing the enter key
    if (e.charCode === 13) {
      login();
    }
  };

  // on user login
  const onUserLogin = (meeting , user) => {
    //check if authorized by confirming is the owner of the room
    let authorized = roomMode ? user.objectId === meeting.user : true;
    if(authorized){
      setIsLoggedIn(true);
      onLogin(meeting, user);
    }else{
      setIsLoggedIn(false);
      logout();
    }
  }

  const logout = (showRoomMessage=true) => {
    //Send logout request
    Backendless.UserService.logout()
    .then( function( ) {
      setUser(null); //reset user
      if(showRoomMessage)
        setError("Host is not authorized to access this room!");
    }).catch( function( error ) {
      alert(error);
    });
  }


  const checkIsUserLoginValid = () => {
      Backendless.UserService.getCurrentUser()
      .then( function( user ) { 
        onUserAutoLogin(user);
      }).catch(error => {
        setIsReady(true);
        setIsLoading(false);
        console.log(error)
        if(error!=null && error.toString().indexOf("Session timeout")!==-1)
          logout(false); //Make sure to logout current user
      });
  }

  const onUserAutoLogin = (user) => {
        setUser(user); 
        if(!email) 
          setEmail(user.email);
        if(!fullname)
          setFullname(user.name); 
        if((meetingInfo || !roomMode) && user)
            if(roomMode){
              let authorized = user.objectId === meetingInfo.user;
              authorized ? setLoggedInMode(true) : logout();
              //Jump directly if edit room mode
              if(editMode){
                onUserLogin(meetingInfo, user);  
              }
            }else
              onUserLogin(meetingInfo, user);
              setTimeout(function(){setIsReady(true);},500);
  }

  const onContinue = () => {
    setTimeout(function(){
      onUserLogin(meetingInfo, user);
    },100);
  }

  const signupMode = () => {
    setError(null);
    setLoginmode(false);
    setForgetpasswordmode(false);
    setSignupmode(true);
  }

  const forgetPasswordMode = () => {
    setError(null);
    setLoginmode(false);
    setSignupmode(false);
    setForgetpasswordmode(true);
  }

  const loginMode = () => {
    setError(null);
    setLoginmode(true);
    setSignupmode(false);
    setForgetpasswordmode(false);
  }

  const cancel = () => {
    setError(null);
    document.getElementById("loginpanel").style.display = "none";
  }


    checkIsUserLoginValid();

  
  return !editProfile && (autoLogin || !isReady) ? (<div></div>) : (
    <div id="loginpanel" className="room-created-box-login box-blur-bg" style={editProfile || (!isLoggedIn && isReady) ? {display:"block"} : {display:"none"}}>
      <div className="room-created-info">
          {(editProfile || signupmode) && <div>
            <div className="title-info"><span>{editProfile ? "Edit Profile" : "Host Signup" }</span></div>
            <h3 className="title">{editProfile ? fullname +"'s Profile":"Let's Get Registered"}</h3>
            <input type="text" name="fullname" value={fullname} onChange={onChangeFullname} placeholder="Full Name" /> 
            <input type="text" disabled={editProfile} name="email" value={email} onChange={onChangeEmail} placeholder="Email" /> 
            <input type="password" name="password" onChange={onChangeSPassword} placeholder="Password"/>
            <input type="password" name="cpassword" onChange={onChangeCPassword} placeholder="Confirm Password"/> 
            {enableCacheLogin && !isLoading && !editProfile && <div className="form-item-full">
              <input className="checkbox" id="cachelogin" type="checkbox" onChange={onChangeCacheLogin} />
              <label htmlFor="cachelogin">Cache Login Details</label> 
            </div>}

            {error && <div className="error">{error}</div>}
            {isLoading?<span>Loading...</span>: <button id="signupusrbutton" className="main" onClick={signupUser}>{editProfile ? "Save": "Signup"}</button>}
            {isLoading || !editProfile?<span></span>: <button id="cancelbuton" className="main" onClick={cancel}>Cancel</button>}
            {!isLoading && !editProfile && <div><button id="welcomecontinue" className="light" onClick={loginMode}>Login</button> | <button id="forgetpasswordbutton" className="light" onClick={forgetPasswordMode}>Forget password</button></div>}
          </div>}
          {!editProfile && forgetpasswordmode && <div>
            <div className="title-info"><span>Host Forget Password</span></div>
            <h3 className="title">Reset Password</h3>
            <input type="text" name="email" onChange={onChangeFEmail} placeholder="Email" /> 
            {error && <div className="error">{error}</div>}
            {isLoading?<span>Loading...</span>: <button id="resetbutton" className="main" onClick={resestUserPassword}>Reset</button>}
            {!isLoading && <div><button className="light" id="welcomecontinue" onClick={loginMode}>Login</button> | <button  id="signupbutton" className="light" onClick={signupMode}>Signup</button></div>}
          </div>}
          
          {!editProfile && loginmode && 
          <div>
            {roomMode ?
            <div className="title-info"> <span>Host Login</span> - <span>{meetingInfo!=null ? (meetingInfo.welcome? meetingInfo.welcome :  slug ) + " Room" : "Loading " + slug + " room... "}</span></div>
            :  
            <div className="title-info"><span>Host Login</span></div>
            }
            <h3 className="title">Let's Get Started</h3>
            <input type="hidden" defaultValue={slug} ref={slugTextInput} onBlur={onChangeSlug}/>
            {!loggedInMode && <input type="text" name="username" onChange={onChangeUsername} placeholder="Email" /> }
            {!loggedInMode && <input type="password" name="password" onChange={onChangePassword} placeholder="Password" onKeyPress={handleKeypress}/> }
            {loggedInMode && <div>Welcome {user?user.name:""}</div> }
            {error && <div className="error">{error}</div>}
            {meetingInfo!=null || ! roomMode
            ?
            (isLoading?<span>Loading...</span>: loggedInMode ? <button className="main" id="welcomecontinue" onClick={onContinue}>Continue</button> : <button className="main" onClick={login}>Login</button>)
            :
            "..."
            }
            {!isLoading && !roomMode && <div><button id="signupbutton" className="light" onClick={signupMode}>Signup</button> | <button className="light" id="forgetpasswordbutton" onClick={forgetPasswordMode}>Forget password</button></div>}
          </div>
          }
      
      </div>
    </div>
  );
};

export default LoginPanel;
