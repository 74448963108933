
// All of these artists are at https://pixabay.com/music/search/mood/laid%20back/
const tracks =  [
  /*
  {
    title: "Cali1",
    artist: "Wataboi",
    audioSrc: "tracks/out.mp3",
    image: "tracks/artwork.jpg",
    color: "rgb(0,174,176)"
  },
  
  {
    title: "Cali2",
    artist: "Wataboi",
    audioSrc: "tracks/out.mp3",
    image: "tracks/artwork.jpg",
    color: "rgb(0,174,176)"
  },*/
  {
    title: "Cali",
    artist: "Wataboi",
    audioSrc: "tracks/cali-wataboi.mp3",
    image: "tracks/artwork.jpg",
    color: "rgb(0,174,176)"
  },
  {
    title: "50",
    artist: "tobylane",
    audioSrc: "tracks/50-tobylane.mp3",
    image: "tracks/artwork2.jpg",
    color: "rgb(255,183,122)"
  },
  {
    title: "I Wonder",
    artist: "DreamHeaven",
    audioSrc: "tracks/iwonder-dreamheaven.mp3",
    image: "tracks/artwork3.jpg",
    color: "rgb(95,159,255)"
  }
];
export default tracks;
