import React, { useRef } from "react";

const RoomCreated = ({slug,editMode}) => {

  //const { REACT_APP_ROOM_URL,REACT_APP_START_URL } = process.env;
  const { REACT_APP_ROOM_URL } = process.env;

  const urlTextInput = useRef(null);
  //const starturlTextInput = useRef(null);
  //const history = useHistory();

  const openRoom = () => {
    window.location.href = urlTextInput.current.value;
  }

  const copyLink = () => {
    // Get the text field 
    let inputEl = urlTextInput.current;
    doCopy(inputEl);
 }
  /*
  const openStart = () => {
    //local open start room interface
    history.push({
      pathname: '/start',
      search: "?slug="+slug,
      state: { autoLogin: true }
    });
  }
  */

    /*
  const copyStartLink = () => {
    //Get the text field
    let inputEl = starturlTextInput.current;
    doCopy(inputEl);
  }
  */

  const doCopy = (inputEl) => {
    const inputValue = inputEl.value.trim();
    if (inputValue) {
     navigator.clipboard.writeText(inputValue)
       .then(() => {
         alert("Link Copied: " + inputValue);
       })
       .catch(err => {
         console.log('Something went wrong', err);
       })
   }
 }

 const hidePanel = () => {
  document.getElementById("room-created").style.display = "none";
 }
  
  return (
    <div id="room-created" className="room-created-box box-blur-bg">
      <div className="room-created-info">
          <h3 className="title">{editMode ? "Room is Updated" : "Room is Created"}</h3>
          <div className="closeIcon" onClick={hidePanel}><span>x</span></div>
          <p>Congratulations! you can share the link in Google Calendar or somewhere else.</p>
          <input type="text outline" readOnly defaultValue={REACT_APP_ROOM_URL+"?slug="+slug} ref={urlTextInput} />
          <button className="main autowidth inline"  onClick={openRoom}>Open Room</button>
          <button className="outline autowidth inline"  onClick={copyLink}>Copy Link</button>
          {/*
          <hr/>
          <p>Use the below link to start your meeting.</p>
          <input type="text" readOnly defaultValue={REACT_APP_START_URL+"?slug="+slug} ref={starturlTextInput} />
          <button onClick={openStart}>Open</button>
          <button onClick={copyStartLink}>Copy Link</button>
          */}
      </div>
    </div>
  );
};

export default RoomCreated;
