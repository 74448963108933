import React from "react";

class CountDown extends React.Component {
    constructor(props) {
      super();
      //Keep current seconds if the remaining time is less then the updated seconds
      if(this.state && props.seconds && this.state.seconds && this.state.seconds < props.seconds){
        props.seconds = this.state.seconds;
      }
      this.state = { time: {}, startingSeconds:props.seconds, seconds: props.seconds,breath:0,maxbreath:0, boxwidth:""};
      this.timer = 0;
      this.startTimer = this.startTimer.bind(this);
      this.countDown = this.countDown.bind(this);
      this.breathSecs = props.breathCycle;
      this.skipCountdown = this.skipCountdown.bind(this);
    }
  
    secondsToTime(secs){
      let hours = Math.floor(secs / (60 * 60));
      let divisor_for_minutes = secs % (60 * 60);
      let minutes = Math.floor(divisor_for_minutes / 60);
  
      let divisor_for_seconds = divisor_for_minutes % 60;
      let seconds = Math.ceil(divisor_for_seconds);
  
      let obj = {
        "h": hours<10?"0"+hours:hours,
        "m": minutes<10?"0"+minutes:minutes,
        "s": seconds<10?"0"+seconds:seconds
      };
      return obj;
    }
  
    componentDidUpdate() {
      if(this.state.startingSeconds !== this.props.seconds){
        this.setState({
          breathSecs:this.props.breathSecs,
          seconds:this.props.seconds,
          startingSeconds:this.props.seconds
        });
        this.startTimer();
      }
    }

    componentDidMount() {
      let timeLeftVar = this.secondsToTime(this.state.seconds);
      this.setState({ time: timeLeftVar });
      this.startTimer();
    }
  
    startTimer() {
      if (this.timer === 0 && this.state.seconds > 0) {
        this.timer = setInterval(this.countDown, 1000);
      }
    }
  
    countDown() {
      // Remove one second, set state so a re-render happens.
      let seconds = this.state.seconds - 1;
      this.setState({
        time: this.secondsToTime(seconds),
        seconds: seconds,
        breath: Math.ceil(seconds/this.breathSecs),
        maxbreath: this.state.maxbreath === 0 ? Math.ceil(seconds/this.breathSecs) : this.state.maxbreath,
        boxwidth: Math.floor(90 / this.state.maxbreath) + "%"
      });
      
      // Check if we're at zero.
      if (seconds === 0) { 
        clearInterval(this.timer);
        this.props.onCountEnd();
      }

      //console.log(seconds,this.state.breath);
     /* if(seconds==8639){
        this.props.onCountEnd();
      }*/
    }

    getValueDisplay(val,sufix){
      return val !== "" && val !== "0" && val !== "00" ? val+sufix : "";
    }

    skipCountdown(){
      //Apply count down end
      clearInterval(this.timer);
      this.props.onCountEnd();
    }
  
    render() {
     // return(
      //  <span>{this.state.breath} {this.getValueDisplay(this.state.time.h,":")}{this.getValueDisplay(this.state.time.m,":")}{this.getValueDisplay(this.state.time.s,"")}</span>
      //);
      //return (<span>{this.state.breath}</span>);
      const currentBreath = this.state.maxbreath-this.state.breath + 1;
      return (
        <span> 
          {currentBreath<this.state.maxbreath?currentBreath: this.state.maxbreath}/{this.state.maxbreath}
          <div className="progress-bar">
            <div>
              {[...Array(this.state.maxbreath)].map((x, i) =>
                <div key={i} style={{width:this.state.boxwidth}} className='progress-box'>&nbsp;</div>
              )}
            </div>
            <div className="progress-bar-inside">
            {this.state.maxbreath - this.state.breath > 0 && [...Array(this.state.maxbreath - this.state.breath)].map((x, i) =>
                <div key={i} style={{width:this.state.boxwidth}} className='progress-box box-on'>&nbsp;</div>
              )}
            </div>
            <div className="progress-value"></div>
          </div>
          <div className="progress-skip">
            <div className="progress-skip-button" onClick={this.skipCountdown}>skip ➤</div>
          </div>
        </span>
      )
    }
  }
  export default CountDown;