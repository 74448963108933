import { useState, useEffect } from 'react';
import React from 'react'
import ReactPlayer from 'react-player'
import "./styles.css";
const VideoPlayer = ({url, onClose, countdown, mode}) => {

  //const [isLoading, setIsLoading] = useState(false);
  const [videoUrl, setVideoUrl] = useState(url);
  
  useEffect(() => {
    setVideoUrl(url)
  },[url]);

  const closeVideo = () => {
    setVideoUrl(null)
    onClose();
  }

  //Init video class name
  let videoClassName = "video-player-mask";
  videoClassName += " " + (countdown ? "countdown-started" : "countdown-stopped");
  videoClassName += " " + mode;

  if(videoUrl && videoUrl!==""){ 
    return  (
    <div className={videoClassName}>
      <div className="close-video" onClick={closeVideo}>x</div>
      <div className="video-player">
          <div className="video-player-content">
              <ReactPlayer url={videoUrl} width="100%" height="100%" playing={true}  volume={countdown?0.4:1}/>
          </div>
      </div>
    </div>
    )
  } else{
      return <div></div>
  }
};

export default VideoPlayer;
