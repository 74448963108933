import React, { useRef } from "react";

const ShareURL = ({slug}) => {

  const { REACT_APP_ROOM_URL } = process.env;

  const urlTextInput = useRef(null);

  const copyLink = () => {
    // Get the text field 
    let inputEl = urlTextInput.current;
    doCopy(inputEl);
 }

  const doCopy = (inputEl) => {
    const inputValue = inputEl.value.trim();
    if (inputValue) {
     navigator.clipboard.writeText(inputValue)
       .then(() => {
         alert("Link Copied: " + inputValue);
       })
       .catch(err => {
         console.log('Something went wrong', err);
       })
   }
 }
  
  return (
    <div className="item share-url-box>">
      <div className="share-url-info">
          <input type="text" readOnly defaultValue={REACT_APP_ROOM_URL+"?slug="+slug} ref={urlTextInput} />
          <div className="share-url-link" title="Copy meeting URL to share" onClick={copyLink}><i className="fa fa-share-alt"></i> <br></br>Share Room</div>
      </div>
    </div>
  );
};

export default ShareURL;
