import React from 'react';
import { useState, useRef } from "react";
import LoginPanel from '../components/ui/LoginPanel';
import RoomParticipantsPanel from '../components/ui/RoomParticipantsPanel';
import StartRoomPanel from '../components/ui/StartRoomPanel';
import Backendless from 'backendless';
import Background from '../components/yoyuma-common/ui/Background';


const StartRoom = (props) => {
  const [meetingInfo, setMeetingInfo] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  const startRoomPanel = useRef(null);
  const [editProfile, setEditProfile] = useState(false);

  const onLogin = (meetingInfo,user) => {
    setUser(user);
    setMeetingInfo(meetingInfo)
    setIsLoggedIn(true);
    //call on start room
    startRoomPanel.current.onStartRoom();
  }

  const logout = () => {
    Backendless.UserService.logout()
    .then( function( ) {
      setIsLoggedIn(false);
      window.location.reload();
    }).catch( function( error ) {
      alert(error);
    });
  }

  const showEditProfile = () => {
    if( document.getElementById("loginpanel"))
      document.getElementById("loginpanel").style.display = "";
    setEditProfile(true);
    //hide listing panel
    if(document.getElementById("room-listing-panel"))
        document.getElementById("room-listing-panel").classList.remove("cd-panel--is-visible");
  }

  const toggleParticipantsList = () => {
      if(document.getElementById("participants-list")){
        if(document.getElementById("participants-list").classList.contains("cd-panel--is-visible")){
          document.getElementById("participants-list").classList.remove("cd-panel--is-visible");
          document.getElementById("room-holder").classList.remove("right-expanded");
        }else{
          document.getElementById("participants-list").classList.add("cd-panel--is-visible");
          document.getElementById("room-holder").classList.add("right-expanded");
        }

    }   
  }

  setTimeout(function(){
     if(document.getElementById("participants-list")){
        document.getElementById("participants-list").classList.add("cd-panel--is-visible");
        document.getElementById("room-holder").classList.add("right-expanded");
      }    
  },500);

  return (
      <div>
        <Background meetingInfo={meetingInfo} />
        {!isLoggedIn &&<div className="white-mask"></div>}
        {user && isLoggedIn && <div className="welcome" >
          <span className="user-icon"><i class="fa fa-user-circle-o" aria-hidden="true"></i></span>
          <span id="editprofile" class="login-button" onClick={showEditProfile}>Welcome {user?user.name:""}!</span> | <span class="logout-button" onClick={logout}>logout</span>
          <span className='menuIcon'><i onClick={toggleParticipantsList} class="fa fa-bars" aria-hidden="true"></i></span>
          </div> }
        <LoginPanel editProfile= {editProfile} onLogin = {onLogin} editMode={props.editMode} roomMode = {true} autoLogin= {props.location.state!=null ? props.location.state.autoLogin :false} />

        {isLoggedIn && <StartRoomPanel  ref={startRoomPanel} meetingInfoProp={meetingInfo}/>}
        {isLoggedIn && <RoomParticipantsPanel meetingInfo={meetingInfo} /> }
      </div>
    );
  }

export default StartRoom;