import React from 'react';
import "./styles.css";
import CountDown from './CountDown';
 
const BreathCountDown = ({breathCycle,remainingSecs,onCountEnd,opacity}) => {
    return (
      <div className="breath-countdown-container"style={{opacity:opacity/100}}>
        <div className="breath-countdown">
          <div className="infodescription" >
          The meeting will slowly start after <br></br><div className="breath-value"><CountDown key ="countdown" breathCycle = {breathCycle} seconds = {remainingSecs * breathCycle} onCountEnd = {onCountEnd} /></div> deep breaths
          </div>
          <div className="container">
            <div className="circle">
              <div className="anim-circle"></div>
            </div>
          </div>
        </div>  
      </div>
    );
}
export default BreathCountDown;