import React, { useRef } from "react";
import Backendless from 'backendless';
import { useEffect,useState} from "react";
import ReactHowler from "react-howler";

const RoomParticipantsPanel = ({meetingInfo}) => {
  const [notify, setNotify] = useState(false);
  const [participants, setParticipants] = useState([]);
  const [newParticipants, setNewParticipants] = useState([]);
  const enableNotificationMsg = window.showNotificationMessage;
  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };
  const prevMeetingInfo = usePrevious(meetingInfo);
  const joinSound = useRef(null);

  let participantsCheck;

  useEffect(() => {
    if(meetingInfo)
      if(meetingInfo!==prevMeetingInfo){
        //Listen to new participants 
         let channel = Backendless.Messaging.subscribe( "participant_join");
         //add selector to list to the required meeting only
         let selector = `meeting = '${meetingInfo.id}'`; 
         channel.addMessageListener(selector,onMessage );
         //
         initParticipantsCheck();
         //
         window.latestParticipants = [];
      }
  });

  const onMessage = ( message ) => {
    console.log(message);
  }
  
  const initParticipantsCheck = () => {
      //initialize interval check every 10secs
      participantsCheck = setInterval(function(){getMeetingParticipants(false)},5000);
      getMeetingParticipants(true);
  }

  useEffect(() => {
    //Reset participants if meeting info is null
    if(!meetingInfo){
      clearInterval(participantsCheck);
      window.latestParticipant = [];
      setParticipants([]);
    }
  },[meetingInfo,participantsCheck]);


  // A comparer used to determine if two entries are equal.
  const isSameParticipant = (a, b) => a.id === b.id && a.objectId === b.objectId;

  // Get items that only occur in the left array,
  // using the compareFunction to determine equality.
  const onlyInLeft = (left, right, compareFunction) => 
    left.filter(leftValue =>
      !right.some(rightValue => 
        compareFunction(leftValue, rightValue)));

  const getMeetingParticipants = (initialMode) => {
    const aliveDelay = 60; //seconds
    let whereClause = `meeting = '${meetingInfo.id}' AND alive < ${aliveDelay}`;
    let queryBuilder = Backendless.DataQueryBuilder.create().setWhereClause( whereClause );
    queryBuilder.addProperties('created', 'rejoined','meeting','name','updated','objectId','id');
    queryBuilder.addProperties('now() as currenttime', 'now() - updated as alive');
    queryBuilder.setSortBy( ["rejoined DESC", "created DESC" ] );
    queryBuilder.setPageSize( 100 ).setOffset( 0 ); //TODO increase the page size by custom API

    Backendless.Data.of( "participants" ).find( queryBuilder )
    .then( function( participants ) {
      let newUsers = [];
      if(initialMode){
        //No new users
      }else{
        newUsers = onlyInLeft(participants, window.latestParticipants, isSameParticipant);
        setNewParticipants(newUsers);
      }
        
        if(newUsers.length>0){
          setNotify(true);
          setTimeout(function(){setNotify(false);},5000);
          //Show notifications of new participants
          for(let i=0;i<newUsers.length;i++){
            showNotifications(newUsers[i].name);
          }
        //
      }
      window.latestParticipants = participants;
      setParticipants(participants);
    })
    .catch( function( error ) {
        window.latestParticipant = [];
        setParticipants([]);
        console.log(error);
    });
  }

  const getNameIcon = (name) => {
    //retun name icon
    if(name && name.length>0){
      let words = name.split(" ");
      return (words.length>1 ? words[0].charAt(0)+words[1].charAt(0) : words.length>0 ? words[0].charAt(0) : name.charAt(0)).toUpperCase();
    }
    return "";
  }

  const secondsToTime = (secs) => {
    let hours = Math.floor(secs / (60 * 60));
    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      "h": hours<10?"0"+hours:hours,
      "m": minutes<10?"0"+minutes:minutes,
      "s": seconds<10?"0"+seconds:seconds
    };
    return obj;
  }

  const getTimeInRoom = (participant) => {
    //get the rejoin time if available otherwise use the creation time 
    let joinTime = participant.rejoined ? participant.rejoined : participant.created;
    let timeObj = secondsToTime((participant.currenttime - joinTime)/ 1000);
    return timeObj.h==="00" ?timeObj.m+":"+timeObj.s:timeObj.h+":"+timeObj.m+":"+timeObj.s;
  }

  const showNotifications = (participantName) => {
    window.showNotification(participantName + " Joined !", "New Paticipant in " + meetingInfo.title);
  }

  return (
    <div  id="participants-list" className="cd-panel cd-panel--from-right js-cd-panel-main">

      <ReactHowler
        src='participant-join.mp3'
        playing={notify}
        ref={joinSound}
      />

       <div class="cd-panel__container">
        <div className="cd-panel__container_content">
  
   
    <div className="participants-list">
      <ul>
      {
        participants && participants.length>0?
        participants.map(participant => {
          const isNewParticipant = newParticipants && newParticipants.indexOf(participant)!==-1;
          return (
              <li key={participant.id} className={isNewParticipant?"new-participant":""}>
                  <div className="icon">{getNameIcon(participant.name)}</div>
                  <div className="name">{participant.name}</div>
                  <div className="time">{getTimeInRoom(participant)}</div>
              </li>
           )
        })
        :
        <li>
          <div className="icon">NA</div>
          <div className="name">No Participants</div>
        </li>
      }
      </ul>
      
    </div>
    {enableNotificationMsg && <div className="notification-msg">You can enable browser notifications to get notified when new participant join!</div>}
    </div>
    </div>
    </div>
  );
};

export default RoomParticipantsPanel;
