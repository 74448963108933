import React from 'react';
import { useCookies } from 'react-cookie';
import moment from 'moment'

const Background = (props) => {
    
    const [cookies, setCookie] = useCookies(['roombgindex','roombgset']);
    let meetingInfo = props.meetingInfo;
    
        if(meetingInfo!=null){
          //Update body background | support for old rooms
            if(meetingInfo.background){
                window.changeBackground(meetingInfo.background);
            }
            //Handle background display
            if(meetingInfo.backgrounds && meetingInfo.backgrounds.length > 0){
               

                //Init date
                let now = new Date();
                let formattedDate =moment(now).format('YYYY-MM-DD');
                //
                //get today background
                let todayBg = null;
                meetingInfo.backgrounds.forEach(function (bg) {
                if(bg.date===formattedDate){
                    todayBg = bg.url;
                }
                });
                //Display background
                if(todayBg===null){
                
                //Apply random rotation
                if(cookies!=null && cookies.roombgindex!=null){
                    let currentIndex = parseInt(cookies.roombgindex);
                    let duration = moment.duration(moment(now).diff(cookies.roombgset));
                    var minutes = duration.asMinutes();
                    //console.log(minutes);
                    //update background if minutes elapsed
                    if(minutes>meetingInfo.bgRotation){
                    //Update index and select background 
                    if(currentIndex<meetingInfo.backgrounds.length-1){
                        currentIndex++;
                    }else{
                        currentIndex = 0;
                    }
                    //
                    //Update background and cookies
                    setCookie('roombgindex', currentIndex, { path: '/' });
                    setCookie('roombgset', now, { path: '/' });
                    }
                    todayBg = meetingInfo.backgrounds[currentIndex].url;
                }else{
                    //Set default background
                    setCookie('roombgindex', 0, { path: '/' });
                    setCookie('roombgset', now, { path: '/' });
                }
                }
                //Show today background
                window.changeBackground(todayBg);
            }
        }

    //return empty div
    return (<div></div>);
}

export default Background;