import React, { useState,useEffect,useRef } from "react";
//import TimezoneSelect, {allTimezones} from "react-timezone-select";
//import moment from "moment-timezone";
import Backendless from 'backendless';
import RoomCreated from "./RoomCreated";
import VideoPlayer from "../yoyuma-common/videoplayer/VideoPlayer";
import Unsplash , { InsertIntoApplicationUploader } from "../yoyuma-common/Unsplash";
import ReactPlayer from "react-player";
//import { Alert } from 'react-st-modal';
//import { Confirm } from 'react-st-modal';

const { REACT_APP_UNSPLASH_ACCESS_KEY} = process.env;


const CreateRoomForm = ({onEnableDisableBubbles, user, onLogout, meeting, editMode = false, show=false}) => {

  /*
  const customAlert = async(title,message) => {
    await Alert(message, title);
  }

  const customConfirm = async(title,message,onConfirm) => {
      const result = await Confirm(message,title);
      if (result) {
        onConfirm();
      }
  }
  */

  // State
  const [isLoading, setIsLoading] = useState(false);
  //const [selectedTimezone, setSelectedTimezone] = useState(Intl.DateTimeFormat().resolvedOptions().timeZone)
  const [selectedUrl, setSelectedUrl] = useState(null);
  const [objectId, setObjectId] = useState(null);
  const [selectedSlug, setSelectedSlug] = useState(null);
  const [name, setName] = useState(null);
  const [roomInfo, setRoomInfo] = useState(null);
  const [welcome, setWelcome] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [step, setStep] = useState(1);
  const [bgURL, setBgURL] = useState(null);
  const [backgrounds, setBackgrounds] = useState([]);
  const [enableBubbles, setEnableBubbles] = useState(true);
  const [enableLighting, setEnableLighting] = useState(true);
  const [enableMusic, setEnableMusic] = useState(true);
  const [hostContent, setHostContent] = useState([]);
  const [content, setContent] = useState(null);
  const [contentTitle, setContentTitle] = useState(null);
  const [contentIndex,setContentIndex]  = useState(-1);
  const [selectURL, setSelectURL] = useState(null);
  const [bgRotation, setBgRotation] = useState(60);
  const [bgDate, setBgDate] = useState(null);
  const [autoPlayContent,setAutoPlayContent] = useState(true);
  const dynamicBackground = false;

  //const [selectedDateTime, setSelectedDateTime] = useState(null);
  
  /*
  const onSelectDateTime = (event) => {
    setSelectedDateTime(event.target.value);
  }
  */
  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevMeeting = usePrevious(meeting);
  const urlInput = useRef();
  const nameInput = useRef();

  useEffect(() => {
    if(meeting!==prevMeeting && meeting != null){
      setSelectedUrl(meeting.link);
      setName(meeting.welcome);
      setTitle(meeting.title);
      setWelcome(meeting.roomName);
      setSelectedSlug(meeting.slug);
      setDescription(meeting.description);
      setBgURL(meeting.background);
      setEnableBubbles(meeting.enableBubbles);
      setEnableLighting(meeting.enableLighting)
      setEnableMusic(meeting.enableMusic);
      setContent(meeting.content);
      setContentTitle(meeting.contentTitle);
      setBgRotation(meeting.bgRotation);
      setAutoPlayContent(meeting.autoplaycontent)
      //console.log("c",meeting.hostcontent.length);
      setHostContent(meeting.hostcontent ? JSON.parse(meeting.hostcontent) : []);
      
      if(meeting.backgrounds){
        setBackgrounds(meeting.backgrounds);
      }else{
        setBackgrounds([]);
      }
      //Update dynamic items
      onEnableDisableBubbles(meeting.enableBubbles);

      //To update background
      if(meeting.background!=="" && meeting.background!=null)
        window.changeBackground(meeting.background);
    
      }
  },[meeting,prevMeeting,onEnableDisableBubbles]);

  useEffect(() => {
    if(bgURL!=null)
      window.changeBackground(bgURL);
  },[bgURL,enableBubbles]);


  const onSelectBgImage = imageUrl => {
    setBgURL(imageUrl);
    window.changeBackground(imageUrl);
    //
    if(dynamicBackground){
      let backgroundsList =[...backgrounds];
      //reset date if available for other background
      backgroundsList.forEach(function (bg) {
        if(bgDate!=null && bg.date===bgDate){
          bg.date = null;
        }
      });
      let backgroundItem = {url:imageUrl,date:bgDate};
      backgroundsList.push(backgroundItem);
      setBackgrounds(backgroundsList);
      //
      document.getElementById("image-add").style.display = "none";
      document.getElementById("bg-date").value = "";
      setBgDate(null);
    }
  }

  const deleteBackground = (index) => {
    if(window.confirm("Are you sure you want to delete !")) {
    //customConfirm("Delete Background","Are you sure you want to delete !",function(){
      let backgroundsList =[...backgrounds];
      backgroundsList.splice(index, 1);
      setBackgrounds(backgroundsList);
    //})
    }
  }

  const previewBackground = (index) => {
      window.changeBackground(backgrounds[index].url);
  }

  const onBgRotationChange = (event) => {
    setBgRotation(event.target.value);
  }
  
  const onBgDateChange = (event) => {
    setBgDate(event.target.value);
  }
  
  const onSelectName = (event) => {
    setName(event.target.value);
  }
  const onSelectUrl= (event) => {
    setSelectedUrl(event.target.value);
  }

  const onTitleChange = (event) => {
    setTitle(event.target.value);
  }

  const onDescriptionChange = (event) => {
    setDescription(event.target.value);
  }

  const onEnableBubblesChange = (event) => {
    setEnableBubbles(event.target.checked);
    onEnableDisableBubbles(event.target.checked);
  }

  const onEnableLightingChange = (event) => {
    setEnableLighting(event.target.checked);
  }

  const onEnableMusicChange = (event) => {
    setEnableMusic(event.target.checked);
  }

  const onEnableAutoPlayContent  = (event) => {
    setAutoPlayContent(event.target.checked);
  }

  const onContinue = () => {
    //add http to the URL
    let url  = selectedUrl!=null ? selectedUrl.toLowerCase() : null;
    if(url && !url.startsWith("http") && !url.startsWith("https"))
      setSelectedUrl("http://" + url);
    if(!name || !selectedUrl){
      //customAlert("Room Information","Room Infomration are not complete!");
      alert("Meeting Infomration are not complete!");
    }else if(!validURL(selectedUrl)){
      //customAlert("Room Information","Invalid Meeting URL!")
      alert("Invalid Meeting URL!")
    }else{
      //goto step 2
      setStep(2);
    }
  }
  const onBack = () => {
    setStep(1);
  }

  const saveRoom  = (roomName,slug) => {

    //Save room
    setTimeout(function(){
      //Save Meeting
      const data = { 
        //start_time:meetingDateTime.utc().unix()*1000,
        //created:1632918538000,
        link:selectedUrl.trim(),
        ___class:"meeting",
        //id: editMode ? meeting.id : null, // use same id for update mode
        objectId: editMode ? meeting.objectId : null, // use same id for update mode
        ownerId:editMode ? meeting.ownerId : null,
        slug:editMode ? meeting.slug : slug , //use same slug for update mode
        status:"WAITING", 
        welcome:roomName,
        title:title,
        description:description,
        background:bgURL,
        backgrounds:JSON.stringify(backgrounds),
        bgRotation:parseInt(bgRotation),
        enableBubbles:enableBubbles,
        enableLighting:enableLighting,
        enableMusic:enableMusic,
        content:content,
        contenttitle:contentTitle,
        hostcontent:JSON.stringify(hostContent),
        autoplaycontent:autoPlayContent,
        user: editMode ? meeting.user : user.objectId
      };

      Backendless.Data.of('meeting').save(data)
      .then(obj => {
        setIsLoading(false);
        setObjectId(obj.objectId);
      })
      .catch(error => {
        setIsLoading(false);
        console.log(error);
        //customAlert("Error","Please try again! \n " + error);
        alert("Please try again! \n " + error);
        setStep(1);
      })
    },100)
  }

  const onSave = () => {
   
    //Validate Meeting info
    //|| !selectedDateTime !selectedTimezone || 
    if(!name || !selectedUrl || !title || !description){
      //customAlert("Room Information","Room Infomration are not complete!");
      alert("Meeting Infomration are not complete!");
      setStep(!name || !selectedUrl ? 1 : 2);
    }else if(!validURL(selectedUrl)){
      //customAlert("Room Information","Invalid Meeting URL!")
      alert("Invalid Meeting URL!")
    } else {

      //Set is loading
      setIsLoading(true);
      //Convert date to selected time zone
      //let meetingDateTime  = moment.tz(selectedDateTime,selectedTimezone.value?selectedTimezone.value:selectedTimezone);
      //console.log(selectedTimezone.value?selectedTimezone.value:selectedTimezone,meetingDateTime.format(),meetingDateTime.utc().format());
      //Edit mode apply update 
      if(editMode){
        //Do not update slug 
        saveRoom(name,null);
      }else{
          //remove quotes, and spaces from slug
          let slugName = name.replace(/['\s]/g, "");
          setSelectedSlug(slugName);
          Backendless.APIServices.invoke('CodelessParticipantKeepAliveService', 'checkmeeting', slugName)
          .then(meeting => {
            if(meeting){
              //Meeting with same slug exists generate unique id
              let updatedSlug = slugName+"-"+makeid(5);
              setSelectedSlug(updatedSlug);
              saveRoom(name,updatedSlug);
            }else{
              //Save room
              saveRoom(name,slugName);
            }
          })
          .catch( function( error ) {
            setIsLoading(false);
            console.log(error);
            alert("Please try again! \n " + error);
            //customAlert("Error","Please try again! \n " + error);
            setStep(1);
          });      
      }


      
  }


  const makeid = (length)=> {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * 
    charactersLength));
    }
    return result;
  }

  
  }

  const validURL= (str) => {
    if(str==null){
      return false;
    }else{
      var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
      return !!pattern.test( str.trim());
    }
  }

  const validateInput = (event) => {
    if (event.charCode === 13) {
      onContinue();
    }else{
      const validChar = (event.charCode >= 65 && event.charCode <= 90) // Small Letters
                        || (event.charCode >= 97 && event.charCode <= 122) //Capital Letters
                        || (event.charCode >= 48 && event.charCode <= 57)  //numbers 
                        || event.charCode === 45 || event.charCode === 95 || event.charCode === 95  || event.charCode === 44 || event.charCode === 46 //- _ * . , 
                        || event.charCode === 33 || event.charCode === 36 || event.charCode === 39 || event.charCode === 40 || event.charCode === 41  //  ! $ ' ( )
                        || event.charCode === 32; // space
      //prevent devault if not character is not  valid
      if(!validChar)
        event.preventDefault();
    }
  }

  const getDefaultRoomInfo = (initialMode) => {
    const whereClause = `key = 'room_default_info'`;
    let queryBuilder = Backendless.DataQueryBuilder.create().setWhereClause( whereClause );
    queryBuilder.addProperties('data');

    Backendless.Data.of( "system_configuration" ).find( queryBuilder )
    .then( function( info ) {
        if(info && info.length>0){
          let roomInfo = JSON.parse(info[0].data);
          setWelcome(roomInfo.welcome);
          setRoomInfo(roomInfo);
          if(!editMode){
            setTitle(roomInfo.title);  
            setDescription(roomInfo.description);
            setEnableBubbles(true); //enable bubbles is always true by default
            setEnableLighting(true); //enable lighting is always true by default
            setEnableMusic(true); //enable music is true by default
          }
        }
    })
    .catch( function( error ) {
        console.log(error);
    });
  }
  
  //Requests default room info if not requested
  if(!roomInfo){
      getDefaultRoomInfo();
  }

  const addContent = () => {
    const name = nameInput.current;
    const url = urlInput.current;
    if(url.value !== "" && name.value !== ""){
      if(ReactPlayer.canPlay(url.value)){
        let contentElements =[...hostContent];
        let contentItem = {name:name.value,url:url.value};
        contentElements.push(contentItem);
        setHostContent(contentElements);
        url.value = "";
        name.value = "";
      }else{
        //customAlert("Room Infomration","Invalid content URL!");
        window.alert("Invalid content URL!");
      }
    }else{
      //customAlert("Room Information","Name and URL are required!");
      window.alert("Name and URL are required!");
    }
  }

  const deleteContent = (index) => {
    //customConfirm("Delete Content","Are you sure you want to delete !",function(){
    if(window.confirm("Are you sure you want to delete !")) {
      //reset selection
      if(hostContent[index].url===content){
        setContent(null);
        setContentTitle(null);
        setContentIndex(-1);
      }
      let contentElements =[...hostContent];
      contentElements.splice(index, 1);
      setHostContent(contentElements);
      //

    ;
    }
    //});
  }


  const onVideoClick = (e,index,fromHost) => {
        if(fromHost)
          setSelectURL(hostContent[index].url);
        else
          setSelectURL(roomInfo.content[index].url);
      
  }

  const onSelectVideo = (e,index,fromHost) => {
      //Reset all previous selections
      const contentItems = Array.from(
        document.querySelectorAll('div.content-item')
      );
      contentItems.forEach(element => {
        if(element)
          element.classList.remove("selected");
      });
      const contentHostItems = Array.from(
        document.querySelectorAll('div.content-item-host')
      );
      contentHostItems.forEach(element => {
        if(element)
          element.classList.remove("selected");
      });

      let contentItemSelector = fromHost ? "content-item-host-" : "content-item-";

      if(contentIndex!==-1){
        setContent(null);
        setContentTitle(null);
        let e = document.getElementById(contentItemSelector+contentIndex);
        if(e)
          e.classList.remove("selected");
      }
      //
      if(index!==contentIndex){
        //Select mode
        setContent(fromHost ? hostContent[index].url : roomInfo.content[index].url);
        setContentTitle(fromHost ? hostContent[index].name : roomInfo.content[index].name);
        setContentIndex(index);
        let e =  document.getElementById(contentItemSelector+index);
          e.classList.add("selected");
      }else{
        setContentIndex(-1);
      }
  }

    
  const hidePanel = () => {
    document.getElementById("room-create-panel").classList.remove("cd-panel--is-visible");
    if(document.getElementById("quote-text"))
      document.getElementById("quote-text").classList.add("animated-show");
  }

 if(show){
  setTimeout(function(){
    if(!document.getElementById("loginpanel") || document.getElementById("loginpanel").style.display === "none") {
      if(document.getElementById("room-create-panel"))
        document.getElementById("room-create-panel").classList.add("cd-panel--is-visible");
        if(document.getElementById("quote-text"))
          document.getElementById("quote-text").classList.remove("animated-show");
    }
  },500);
 }

 const addImage = () => {
   if( document.getElementById("image-add").style.display === "none")
    document.getElementById("image-add").style.display = "block";
  else 
    document.getElementById("image-add").style.display = "none";
 }
 
 
  return (
    <div>
    {objectId && <RoomCreated slug={selectedSlug} editMode={editMode}/>}
    {!objectId && <div  id="room-create-panel" className= "create-room-form-holder cd-panel cd-panel--from-right js-cd-panel-main">
       <div className="cd-panel__container">
       <div className="cd-panel__container_content">
       <header className="cd-panel__header">
        <h3 className="title margin-top">{editMode ? "Edit " + name + " Room": (roomInfo ? roomInfo.formRoomTitle : "")}</h3>
        <div  onClick= {hidePanel} className="cd-panel__close js-cd-close">X</div >
      </header>
      <div className="cd-panel__content">
       <div className="create-room-form-info">
            {editMode && <center><p><i>Room url will not be affected!</i></p></center>}
           
            <div id="step1" style={{display:step === 1?'block':'none'}}>
            <div className="form-item-full">
              <input id="meeting-url" type="url" value={selectedUrl} pattern="https://.*" required placeholder={roomInfo ? roomInfo.formRoomUrlMessage : ""} style={{width:"90%"}} onChange={onSelectUrl} />              
              </div>
              <div className="form-item-full">
                <label>{welcome ? welcome.split("[ROOM_NAME]")[0]:""} </label>
                <div>
                <input id="room-name" value={name} type="text" required placeholder={roomInfo ? roomInfo.formRoomNameMessage : ""} onKeyPress={validateInput} style={{width:"90%"}}  maxLength="32"  onChange={onSelectName} />
                </div> 
                <label>{welcome ? welcome.split("[ROOM_NAME]")[1]:""}</label>            
            </div>
            <div className="form-item-full">
            <br></br><button id="continue-button" className="main autowidth" onClick={onContinue}>Continue</button>
            </div>
            </div>

            <div id="step2" style={{display:step === 2?'block':'none'}}>
            <div>

           

              <div className="form-item-full">
                <label>Title</label>
                <input id="room-title" type="text" value={title} maxLength="100" required placeholder="Title" style={{width:"90%"}} onChange={onTitleChange} />              
              </div>
              
              <div className="form-item-full">
              <label>Description</label>
                <textarea  id="room-description" rows={3}  value={description} maxLength="125" required placeholder="Description" style={{width:"90%"}}  onChange={onDescriptionChange} />  
              </div>

              {roomInfo && roomInfo.content && roomInfo.content.length > 0 && <div className="form-item-full">
                <label>Content <small>(Click to Play, Doubleclick to play and select.)</small></label>
 

                  <div className="content-listing">
                    <br></br>
                    <label><small>Add Content:</small></label><br></br>
                    <input id="video-name" ref={nameInput} type="text" className="inline-input" maxLength="100"  placeholder="Content Title" style={{width:"80%", display:"inline"}}  />
                    <input id="video-url" ref={urlInput} type="text" className="inline-input" maxLength="100"  placeholder="Enter Video URL" style={{width:"80%", display:"inline"}}  /> 
                    <div id="video-add" onClick={addContent} className="addVideoButton">+</div> 
                    <div className="content-items">
                      <ul>
                      {
                        roomInfo.content.map((content_item,index) => {
                          return (
                              <li key={index} >
                                <div className={meeting && meeting.content === content_item.url ? "content-item content-item-host selected" : "content-item content-item-host"} id={"content-item-"+index}>
                                  <div onDoubleClick={(e) => {onSelectVideo(e,index,false)}} onClick={(e) => {onVideoClick(e,index,false)}} className="video-icon"></div>
                                  <div onDoubleClick={(e) => {onSelectVideo(e,index,false)}} onClick={(e) => {onVideoClick(e,index,false)}}  className="video-title" title={content_item.name}>{content_item.name}</div>
                                </div>
                              </li>
                          )
                        })
                      } 
                      {
                        hostContent && hostContent.map((content_item,index) => {
                          return (
                              <li key={index} >
                                <div className={meeting && meeting.content === content_item.url ? "content-item selected" : "content-item"} id={"content-item-host-"+index}>
                                  <div onDoubleClick={(e) => {onSelectVideo(e,index,true)}} onClick={(e) => {onVideoClick(e,index,true)}} className="video-icon"></div>
                                  <div onDoubleClick={(e) => {onSelectVideo(e,index,true)}} onClick={(e) => {onVideoClick(e,index,true)}}  className="video-title" title={content_item.name}>{content_item.name}</div>
                                  <div onClick={() => {deleteContent(index)}} className="deleteVideoButton">X</div>
                                </div>
                              </li>
                          )
                        })
                      } 
                      </ul>
                    </div>
                  </div>
                  <VideoPlayer url={selectURL} onClose = {()=>{setSelectURL(null)}}  mode="video-full-mode video-right-mode" />
                  <div className="form-item-full">
                <div>
                    <input id="autoplay" className="checkbox" type="checkbox" checked={autoPlayContent} required onChange={onEnableAutoPlayContent} /> 
                    <label htmlFor="autoplay">Automaticly play video</label>        
                  </div>
              </div>
              </div>}
              <div className="form-item-full">
                <label>Room Background Image</label>
                {!dynamicBackground && editMode && meeting && meeting.background && <div style={{ margin: "10px" }}>
                  <img src={meeting.background} width="100" align="absmiddle" alt="Previous Selected"/> <i>Previous selected image</i>
                </div>}
                {dynamicBackground && <button className="add-image outline" onClick={addImage}>Add Image</button>}
              </div>

              {dynamicBackground &&<fieldset id="image-add" style={{ display: "none" }}>
              <legend>Background Image Selector</legend>
              <div className="form-item-full">
                <label>Select Date (Keep empty for random rotation)</label>
                <input id="bg-date" type="date" value={bgDate} placeholder="Date" style={{width:"90%"}} onChange={onBgDateChange} />
              </div>

              <div className="form-item-full">
              <label>Select Image</label>
                <div style={{ display: "flex" }}>
                  <div className="bg-image-selector">
                  {
                    // eslint-disable-next-line
                    <Unsplash
                      height={300}
                      accessKey={REACT_APP_UNSPLASH_ACCESS_KEY}
                      applicationName="unsplash_react"
                      Uploader={InsertIntoApplicationUploader}
                      photoRatio={16 / 9}
                      preferredSize={{ width: 1920, height: 1440 }}
                      onFinishedUploading={onSelectBgImage}
                    />
                  }
                  </div>
                </div>
                </div>
                </fieldset>}

                {!dynamicBackground && <div className="bg-image-selector">
                  {
                    // eslint-disable-next-line
                    <Unsplash
                      height={300}
                      accessKey={REACT_APP_UNSPLASH_ACCESS_KEY}
                      applicationName="unsplash_react"
                      Uploader={InsertIntoApplicationUploader}
                      photoRatio={16 / 9}
                      preferredSize={{ width: 1920, height: 1440 }}
                      onFinishedUploading={onSelectBgImage}
                    />
                  }
                  </div>}
                                
                {backgrounds && backgrounds.length > 0 && <div className="form-item-full">
                <label>Selected Background Images</label>
                  <div className="backgrounds-listing content-listing">
                    <div className="content-items">
                      <ul>
                      {
                        backgrounds.map((bg_item,index) => {
                          return bg_item && bg_item.url ? (
                              <li key={index} >
                                <div className="content-item bg-item" id={"content-item-"+index}>
                                  <img onClick={() => {previewBackground(index)}} src={bg_item.url}  alt="background" align="absmiddle" />
                                  <div  className="bg-date" title={bg_item.date}>{bg_item.date}</div>
                                  <div onClick={() => {deleteBackground(index)}} className="deleteBgButton">X</div>
                                </div>
                              </li>
                          ) : (<div key={index}></div>)
                        })
                      } 
                      </ul>
                    </div>
                  </div>
              </div>}
              {dynamicBackground &&<div className="form-item-full">
                <label>Background Rotate every X minutes</label>
                <input id="bg-rotation" type="number" value={bgRotation} required placeholder="minutes" style={{width:"90%"}} onChange={onBgRotationChange} />
              </div>}

              <div className="form-item-full">
                <div>
                    <input id="b1" className="checkbox" type="checkbox" checked={enableBubbles} required onChange={onEnableBubblesChange} /> 
                    <label htmlFor="b1">Enable Background Bubbles</label>        
                  </div>
              </div>

             

              <div className="form-item-full">
                <div>
                    <input id="m1" className="checkbox" type="checkbox" checked={enableMusic} required onChange={onEnableMusicChange} /> 
                    <label htmlFor="m1">Enable Music</label>        
                  </div>
              </div>

              {enableMusic && <div className="form-item-full">
                <div>
                    <input id="b2" className="checkbox" type="checkbox" checked={enableLighting} required onChange={onEnableLightingChange} /> 
                    <label htmlFor="b2">Enable Audio Player Background Lighting Effects</label>        
                  </div>
              </div>}

            </div>
           
            
            {/*
            <label>Meeting Date/Time</label>
            <div className="flex-container">
              <div  className="flex-item-left">
                <input type="datetime-local" required placeholder="Enter Meeting Datetime"  style={{width:"95%",padding:"7px"}} onChange={onSelectDateTime} />
              </div>
              <div  className="flex-item-right">
                <div className="select-wrapper">
                  <TimezoneSelect
                    value={selectedTimezone}
                    onChange={setSelectedTimezone}
                    placeholder="Timezone"
                    timezones={{
                      ...allTimezones,
                      'America/Lima': 'Pittsburgh',
                      'Europe/Berlin': 'Frankfurt',
                      'Asia/Beirut': 'Beirut',
                    }}
                  />
                  </div>
              </div>
            </div>
            */}
            {isLoading
              ?<button className="main autowidth inline" disabled>Saving...</button>
              :<div><button className="main autowidth inline" id="save-button" onClick={onSave}>{editMode ? "Update" : "Create"}</button> <button className="back outline autowidth inline" onClick={onBack}>Back</button></div>
            }
             </div>
        </div>
    </div> </div></div>
    </div>}
    </div>
  );
};

export default CreateRoomForm;
