import React from "react";
import Backendless from 'backendless';
import { useState} from "react";

const RoomsListingPanel = ({user}) => {
  const { REACT_APP_START_URL } = process.env;
  const [rooms, setRooms] = useState(null);
  const [offset, setOffset] = useState(0);
  const [totalRooms, setTotalRooms] = useState(0);
  const [loading, setLoading] = useState(false);
  const pageDelta = 20;
  const URL_BASE = window.location.href.split("#")[0];


  const getUserRooms = (offset) => {
    setLoading(true);
    //let whereClause = `owner = '${meetingInfo.id}' AND alive < ${aliveDelay}`;
    //let whereClause = `user = '${user.objectId}'`;
    let queryBuilder = Backendless.DataQueryBuilder.create();
    //queryBuilder.setWhereClause( whereClause );
    //queryBuilder.addProperties('created', 'rejoined','meeting','name','updated','objectId','id');
    //queryBuilder.addProperties('now() as currenttime', 'now() - updated as alive');
    queryBuilder.setSortBy( ["created DESC"] );
    queryBuilder.setPageSize( pageDelta ).setOffset( offset ); //TODO increase the page size by custom API

    Backendless.Data.of( "meeting" ).getObjectCount()
    .then( function( count ) {
      setTotalRooms(count);
      //
      Backendless.Data.of( "meeting" ).find( queryBuilder )
      //Backendless.Data.of( "meeting" ).find()
      .then( function( rooms ) {
        setLoading(false);
        //window.latestParticipants = participants;
        setRooms(rooms);
      })
      .catch( function( error ) {
          //window.latestParticipant = [];
          setLoading(false);
          setRooms([]);
          console.log(error);
      });
    })
    .catch( function( error ) {
    });


    
  }

  const nextPage = () => {
    let hasNext = totalRooms > offset + pageDelta;
    if(!loading && hasNext) {
      setOffset(offset+pageDelta);
      getUserRooms(offset+pageDelta);
    }
  }

  const previousPage = () => {
    if(!loading && offset > 0){
      setOffset(offset-pageDelta);
      getUserRooms(offset-pageDelta);
    }
  }

  const deleteRoom = (roomId) => {
    if(window.confirm("Are you sure you want to delete?")){
      Backendless.Data.of( "meeting" ).remove( { objectId:roomId } )
      .then( function( timestamp ) {
        //Refresh rooms
        getUserRooms(offset);
       })
      .catch( function( error ) {
        console.log(error);
       });
    }
  }

  const editRoom = (slug) => {
    window.location.href = URL_BASE + "#/edit?slug="+ slug;
    window.location.reload();
  }

  const newRoom = () => {
    window.location.href = URL_BASE+ "#/new";
    window.location.reload();
  }
  
  const hidePanel = () => {
      document.getElementById("room-listing-panel").classList.remove("cd-panel--is-visible");
  }

  if(!rooms && user && !loading)
    getUserRooms(offset);
  
  return (
    <div id="room-listing-panel" className="cd-panel cd-panel--from-right js-cd-panel-main">
       <div className="cd-panel__container">
        <div className="cd-panel__container_content">
       <header className="cd-panel__header">
        <h3 className="title margin-top">Manage Rooms</h3>
        <div  onClick= {hidePanel} className="cd-panel__close js-cd-close">X</div >
      </header>
      <div className="cd-panel__content">
    <div className="room-list">
      <div className="room-list-scroll">
      <ul>
      {
        rooms && rooms.length>0?
        rooms.map(room => {
          return (
            <li key={room.id} className={"status_" + room.status} title={room.status}>
              <div className="status-icon"></div>
              <a title={new Date(room.created).toDateString() + " | " + (room.title?room.title:"Untitled")  + " | " +  (room.description? room.description : "NA") } href={REACT_APP_START_URL+"?slug="+room.slug} rel="noreferrer" target="_blank" class="room-slug">{room.welcome ? room.welcome : room.slug}</a>
              <div className="edit-button"   title="Edit"  slug={room.slug}  onClick={() => editRoom(room.slug)}>&#x270E;</div>&nbsp;
              <div className="delete-button" title="Delete" objectId={room.objectId} onClick={() => deleteRoom(room.objectId)}>&#x2716;</div>
            </li>
           )
        })
        :
        <li><center>{loading ? "Loading...": offset > 0 ? "No more rooms found!": "No results found!"}</center></li>
      }
      </ul>
    
      </div>
      <div className="paging">
        <div id="previous-page" onClick={previousPage} className={offset > 0 ? "nav" : "nav disabled"}> « previous</div> 
        <div className="nav-info">{loading ? "loading..." : "Page: " + ((offset/pageDelta)+1) + "/"  + Math.ceil(totalRooms/pageDelta) }</div>
        <div id="next-page" className={totalRooms > offset + pageDelta ? "nav" : "nav disabled"} onClick={nextPage}> next »</div>
        <button className="main new-room-link" onClick={newRoom}>+ New Room</button>
      </div>
    </div>
    </div>
    </div>
    </div>
    </div>
  );
};

export default RoomsListingPanel;
