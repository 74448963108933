import React  from 'react';
import { useState } from "react";
import { useCookies } from 'react-cookie';
import Bubbles from '../components/yoyuma-common/bubbles/Bubbles';
import LoginPanel from '../components/ui/LoginPanel';
import RoomsListingPanel from '../components/ui/RoomsListingPanel';
import CreateRoomForm from '../components/ui/CreateRoomForm';
import Background from '../components/yoyuma-common/ui/Background';
import Backendless from 'backendless';
import moment from 'moment'

const ManageRoom = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [defaultConifg, setDefaultConifg] = useState(null)
  const [user, setUser] = useState(null);
  const [enableBubbles, setEnableBubbles] = useState(true);
  const [meeting, setMeeting] = useState(null);
  const [editProfile, setEditProfile] = useState(false);
  const [hostBgInfo, setHostBgInfo] = useState(null);
  const URL_BASE = window.location.href.split("#")[0];
  const [quotes, setQuotes] = useState(null);
  const [quotesAutor, setQuotesAuthor] = useState(null);
  const [cookies, setCookie] = useCookies(['todayquotedate','todayquote','todayquoteauther']);

  //Update background image
  document.body.classList.add("admin");
  

  const onLogin = (meeting,user) => {
    setIsLoggedIn(true);
    setUser(user);
    setMeeting(meeting);
  }

  const logout = () => {
    Backendless.UserService.logout()
    .then( function( ) {
      setIsLoggedIn(false);
      window.location.reload();
    }).catch( function( error ) {
      alert(error);
    });
  }

  const onEnableDisableBubbles = (enabled) => {
    setEnableBubbles(enabled);
  }

  const showListingPanel = () => {
    if(document.getElementById("room-create-panel"))
      document.getElementById("room-create-panel").classList.remove("cd-panel--is-visible");
   
      if(props.showForm){
        window.location.href = URL_BASE + "#/manage";
      }else if( document.getElementById("room-listing-panel") && (!document.getElementById("loginpanel") || document.getElementById("loginpanel").style.display === "none"))
        document.getElementById("room-listing-panel").classList.add("cd-panel--is-visible");
  }

  const showEditProfile = () => {
    if( document.getElementById("loginpanel"))
      document.getElementById("loginpanel").style.display = "";
    setEditProfile(true);
    //hide listing panel
    if(document.getElementById("room-listing-panel"))
        document.getElementById("room-listing-panel").classList.remove("cd-panel--is-visible");
  }

 
    

  if(props.showRooms)
    setTimeout(function(){
      showListingPanel();
    },500);

    const getDefaultConfig = () => {
      const whereClause = `key = 'room_default_info'`;
      let queryBuilder = Backendless.DataQueryBuilder.create().setWhereClause( whereClause );
      queryBuilder.addProperties('data');
  
      Backendless.Data.of( "system_configuration" ).find( queryBuilder )
      .then( function( info ) {
          if(info && info.length>0){
            
            let dConfig = JSON.parse(info[0].data);
            setDefaultConifg(dConfig.hostBackgrounds);
            if(dConfig.hostBackgrounds){
              let hostBgInfo = {};
              hostBgInfo.backgrounds = JSON.parse(dConfig.hostBackgrounds);
              hostBgInfo.bgRotation = dConfig.hostBgRotation;
              if(hostBgInfo.backgrounds.length>0)
                setHostBgInfo(hostBgInfo);
              else
                applyRandomBg();
            }else{
              //Show random background 
              applyRandomBg();
            }
          }
      })
      .catch( function( error ) {
          console.log(error);
      });
    }

    const requestQuotes = () => {
        setQuotes("...");
        //Init quote daily
        let requestQuote = false;
        let now = new Date();
        if(cookies!=null && cookies.todayquote!=null){
          //check for elapsed 24 hours to request new quote
          let duration = moment.duration(moment(now).diff(cookies.todayquotedate));
          let hours = duration.asHours();
          if(hours >= 24){
            //Request new quote
            setCookie('todayquotedate', now, { path: '/' });
            requestQuote = true;
          }else{
            //use same quotes
            setQuotes(cookies.todayquote);
            setQuotesAuthor(cookies.todayquoteauther);
            setTimeout(function(){
              if(document.getElementById("quote-text"))
                document.getElementById("quote-text").classList.add("animated-show");
            },1000);
          }
        }else{
          //Request new quote
          setCookie('todayquotedate', now, { path: '/' });
          requestQuote = true;
        }

        //Fetsh quotes
        if(requestQuote){
          fetch("https://api.quotable.io/random?tags=humorous|courage|happiness|leadership|philosophy|virtue|wisdom&minLength=20&maxLength=50")
          .then(res => res.json())
          .then(
            (result) => {
              setCookie('todayquote', result.content, { path: '/' });
              setCookie('todayquoteauther', result.author, { path: '/' });
              setQuotes(result.content);
              setQuotesAuthor(result.author);
              setTimeout(function(){
                if(document.getElementById("quote-text"))
                  document.getElementById("quote-text").classList.add("animated-show");
              },500);
            },
            (error) => {
              console.log(error);
            }
          )
        }
    }

    const applyRandomBg = () => {
      document.body.style.backgroundImage = "url('https://source.unsplash.com/1920x1080/?meditation,nature,sun,beach,montain')";
    }

    //Requests default room info if not requested
    // !props.showForm
    if(!defaultConifg){
      getDefaultConfig();
    }

    if(quotes===null && quotes!=="...")
      requestQuotes();

  return (
      <div>
        {hostBgInfo && <Background meetingInfo={hostBgInfo} />}
        {quotes && <div id="quote-text" className="animated-typing">
          <div className="animated-text-bg">“{quotes}”<div className="quote-auther">{quotesAutor}</div></div>
        </div>}
        {props.showForm && <div className="white-mask"></div>}
        {user && isLoggedIn && <div className="welcome" ><span className="user-icon"><i className="fa fa-user-circle-o" aria-hidden="true"></i></span><span id="editprofile" class="login-button" onClick={showEditProfile}>Welcome {user?user.name:""}!</span> | <span class="logout-button" onClick={logout}>logout</span></div> }
        {isLoggedIn &&  <button className="manage-rooms-button outline" onClick={showListingPanel} >&#x2692; Manage Rooms</button>}
        {isLoggedIn && <RoomsListingPanel  user = {user} />}
        {isLoggedIn && <CreateRoomForm  show = {props.showForm} editMode = {props.editMode} meeting = {meeting} onEnableDisableBubbles={onEnableDisableBubbles}  user = {user}  onLogout={logout}/> }
        <LoginPanel editProfile= {editProfile} onLogin = {onLogin} editMode={props.editMode} roomMode = {props.editMode} autoLogin= {isLoggedIn} />
        {props.showForm && enableBubbles && <Bubbles />}
      </div>
    );
  }

export default ManageRoom;